/**
 * External Dependencies
 */
import 'jquery';

// Offset html anchor for fixed header
require('./modules/anchor-scroll');

/* require('./modules/CookieConsent'); */

$(document).ready(() => {
  console.log('Hello world');
});

const nav = document.querySelector('header');
const scrollDown = 'scroll-down';

window.addEventListener('scroll', () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll <= 0) {
    nav.classList.remove(scrollDown); // scrollUp
    return;
  } else {
    nav.classList.add(scrollDown);
  }
});

const burgerMenu = document.querySelector('.burger-menu a');
const b = document.body;
const navDrop = document.querySelector('.nav-drop');

burgerMenu.addEventListener('click', function() {
  if (b.style.overflow != 'hidden') {
    openNav();
  } else {
    closeNav();
  }
});

const closeNav = () => {
  b.style.overflow = 'scroll';
  navDrop.style.transform = 'translate(0, -500px)';
};

const openNav = () => {
  b.style.overflow = 'hidden';
  navDrop.style.transform = 'translate(0, 0)';
  nav.classList.add(scrollDown);
};

const li = document.querySelectorAll('.nav-drop-item');

li.forEach(li => {
  li.addEventListener('click', function() {
    console.log('i');
    b.style.overflow = 'scroll';
    navDrop.style.transform = 'translate(0, -500px)';
  });
});

/* eslint-disable */
/* prettier-ignore */
function scrollToAnchor(hash) {

  setTimeout(function() { },10)
          var target = $(hash),
          // headerHeight = $(".nav-links").height() + 10; // Get fixed header height
          target = target.length ? target : $('[name=' + hash.slice(1) +']');
          
          if (target.length)
          {
              if (!$('.menu-content').hasClass('scroll-down')) { // If header-top height > scrolling-header
                  console.log('top');
                  $('html,body').animate({
                      scrollTop: target.offset().top - 120 // add Fix height OR var headerheight
                  }, 10);
               
              } else {
                  console.log('bottom');
                  $('html,body').animate({
                      scrollTop: target.offset().top - 66
                  }, 10);
              }
              return false;
          }
     
  }

if (window.location.hash) {
  $(window).load(function() {
    scrollToAnchor(window.location.hash);
  });
}

$('a[href*=\\#]:not([href=\\#])').click(function(event) {
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
  ) {
    scrollToAnchor(this.hash);
    event.preventDefault();
  }
});
